/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    dateOfBirth
    phoneNumber
    nationality
    hearAboutUs
    referral
    taxId
    taxIdType
    country
    state
    city
    street
    postalCode
    legalAgreements
    usAccreditedInvestor {
      annualIncome
      netWorth
      includesSpouse
      accreditedInvestorCondition
      accreditedInvestorFlag
      accreditedInvestorExemption
      __typename
    }
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    identification {
      idType
      idNumber
      taxIdType
      taxId
      __typename
    }
    kycDetails {
      type
      identificationNumber
      issuingCountry
      issueDate
      expirationDate
      reason
      verificationId
      documentPhotos
      verifiedDelivered
      rejectedDelivered
      __typename
    }
    Documents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    minimumInvestment
    minimumDeposit
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    withdrawals {
      nextToken
      __typename
    }
    loans {
      nextToken
      __typename
    }
    investments {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    clientId
    accountId
    wallets {
      __typename
    }
    otpCode {
      code
      timestamp
      __typename
    }
    onboardingStatus
    kycVerificationStatus
    monthlyInterestPayoutsOptIn
    migrationStatus
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    dateOfBirth
    phoneNumber
    nationality
    hearAboutUs
    referral
    taxId
    taxIdType
    country
    state
    city
    street
    postalCode
    legalAgreements
    usAccreditedInvestor {
      annualIncome
      netWorth
      includesSpouse
      accreditedInvestorCondition
      accreditedInvestorFlag
      accreditedInvestorExemption
      __typename
    }
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    identification {
      idType
      idNumber
      taxIdType
      taxId
      __typename
    }
    kycDetails {
      type
      identificationNumber
      issuingCountry
      issueDate
      expirationDate
      reason
      verificationId
      documentPhotos
      verifiedDelivered
      rejectedDelivered
      __typename
    }
    Documents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    minimumInvestment
    minimumDeposit
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    withdrawals {
      nextToken
      __typename
    }
    loans {
      nextToken
      __typename
    }
    investments {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    clientId
    accountId
    wallets {
      __typename
    }
    otpCode {
      code
      timestamp
      __typename
    }
    onboardingStatus
    kycVerificationStatus
    monthlyInterestPayoutsOptIn
    migrationStatus
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    dateOfBirth
    phoneNumber
    nationality
    hearAboutUs
    referral
    taxId
    taxIdType
    country
    state
    city
    street
    postalCode
    legalAgreements
    usAccreditedInvestor {
      annualIncome
      netWorth
      includesSpouse
      accreditedInvestorCondition
      accreditedInvestorFlag
      accreditedInvestorExemption
      __typename
    }
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    identification {
      idType
      idNumber
      taxIdType
      taxId
      __typename
    }
    kycDetails {
      type
      identificationNumber
      issuingCountry
      issueDate
      expirationDate
      reason
      verificationId
      documentPhotos
      verifiedDelivered
      rejectedDelivered
      __typename
    }
    Documents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    minimumInvestment
    minimumDeposit
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    withdrawals {
      nextToken
      __typename
    }
    loans {
      nextToken
      __typename
    }
    investments {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    clientId
    accountId
    wallets {
      __typename
    }
    otpCode {
      code
      timestamp
      __typename
    }
    onboardingStatus
    kycVerificationStatus
    monthlyInterestPayoutsOptIn
    migrationStatus
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createDocuments = /* GraphQL */ `mutation CreateDocuments(
  $input: CreateDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  createDocuments(input: $input, condition: $condition) {
    id
    key
    bucketName
    fileName
    name
    category
    entityType
    entityId
    User {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentsMutationVariables,
  APITypes.CreateDocumentsMutation
>;
export const updateDocuments = /* GraphQL */ `mutation UpdateDocuments(
  $input: UpdateDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  updateDocuments(input: $input, condition: $condition) {
    id
    key
    bucketName
    fileName
    name
    category
    entityType
    entityId
    User {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentsMutationVariables,
  APITypes.UpdateDocumentsMutation
>;
export const deleteDocuments = /* GraphQL */ `mutation DeleteDocuments(
  $input: DeleteDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  deleteDocuments(input: $input, condition: $condition) {
    id
    key
    bucketName
    fileName
    name
    category
    entityType
    entityId
    User {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentsMutationVariables,
  APITypes.DeleteDocumentsMutation
>;
export const createLoan = /* GraphQL */ `mutation CreateLoan(
  $input: CreateLoanInput!
  $condition: ModelLoanConditionInput
) {
  createLoan(input: $input, condition: $condition) {
    id
    startDate
    endDate
    amount
    currency
    coverImage
    sector
    title
    country
    countryFlag
    textOverview
    textUseOfFunds
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLoanMutationVariables,
  APITypes.CreateLoanMutation
>;
export const updateLoan = /* GraphQL */ `mutation UpdateLoan(
  $input: UpdateLoanInput!
  $condition: ModelLoanConditionInput
) {
  updateLoan(input: $input, condition: $condition) {
    id
    startDate
    endDate
    amount
    currency
    coverImage
    sector
    title
    country
    countryFlag
    textOverview
    textUseOfFunds
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLoanMutationVariables,
  APITypes.UpdateLoanMutation
>;
export const deleteLoan = /* GraphQL */ `mutation DeleteLoan(
  $input: DeleteLoanInput!
  $condition: ModelLoanConditionInput
) {
  deleteLoan(input: $input, condition: $condition) {
    id
    startDate
    endDate
    amount
    currency
    coverImage
    sector
    title
    country
    countryFlag
    textOverview
    textUseOfFunds
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLoanMutationVariables,
  APITypes.DeleteLoanMutation
>;
export const createWithdrawals = /* GraphQL */ `mutation CreateWithdrawals(
  $input: CreateWithdrawalsInput!
  $condition: ModelWithdrawalsConditionInput
) {
  createWithdrawals(input: $input, condition: $condition) {
    id
    userID
    amount
    currency
    datePaidOut
    createdAt
    updatedAt
    withdrawalStatus
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWithdrawalsMutationVariables,
  APITypes.CreateWithdrawalsMutation
>;
export const updateWithdrawals = /* GraphQL */ `mutation UpdateWithdrawals(
  $input: UpdateWithdrawalsInput!
  $condition: ModelWithdrawalsConditionInput
) {
  updateWithdrawals(input: $input, condition: $condition) {
    id
    userID
    amount
    currency
    datePaidOut
    createdAt
    updatedAt
    withdrawalStatus
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWithdrawalsMutationVariables,
  APITypes.UpdateWithdrawalsMutation
>;
export const deleteWithdrawals = /* GraphQL */ `mutation DeleteWithdrawals(
  $input: DeleteWithdrawalsInput!
  $condition: ModelWithdrawalsConditionInput
) {
  deleteWithdrawals(input: $input, condition: $condition) {
    id
    userID
    amount
    currency
    datePaidOut
    createdAt
    updatedAt
    withdrawalStatus
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWithdrawalsMutationVariables,
  APITypes.DeleteWithdrawalsMutation
>;
export const createInvestment = /* GraphQL */ `mutation CreateInvestment(
  $input: CreateInvestmentInput!
  $condition: ModelInvestmentConditionInput
) {
  createInvestment(input: $input, condition: $condition) {
    id
    startDate
    endDate
    actualEndDate
    annualInterestRate
    renewFlag
    renewOption
    currency
    principleAmount
    InterestCycleDays
    tenorDays
    expectedTotalInterest
    expectedInterestPerInterestCycle
    expectedNumberOfInterestCycles
    interestPayoutOption
    funded
    fundedAt
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    investmentAgreement {
      signed
      signature
      signedAgreementUrl
      signedAt
      __typename
    }
    isBackdated
    nextInterestPerInterestCycle
    isRenewed
    investmentStep
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvestmentMutationVariables,
  APITypes.CreateInvestmentMutation
>;
export const updateInvestment = /* GraphQL */ `mutation UpdateInvestment(
  $input: UpdateInvestmentInput!
  $condition: ModelInvestmentConditionInput
) {
  updateInvestment(input: $input, condition: $condition) {
    id
    startDate
    endDate
    actualEndDate
    annualInterestRate
    renewFlag
    renewOption
    currency
    principleAmount
    InterestCycleDays
    tenorDays
    expectedTotalInterest
    expectedInterestPerInterestCycle
    expectedNumberOfInterestCycles
    interestPayoutOption
    funded
    fundedAt
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    investmentAgreement {
      signed
      signature
      signedAgreementUrl
      signedAt
      __typename
    }
    isBackdated
    nextInterestPerInterestCycle
    isRenewed
    investmentStep
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvestmentMutationVariables,
  APITypes.UpdateInvestmentMutation
>;
export const deleteInvestment = /* GraphQL */ `mutation DeleteInvestment(
  $input: DeleteInvestmentInput!
  $condition: ModelInvestmentConditionInput
) {
  deleteInvestment(input: $input, condition: $condition) {
    id
    startDate
    endDate
    actualEndDate
    annualInterestRate
    renewFlag
    renewOption
    currency
    principleAmount
    InterestCycleDays
    tenorDays
    expectedTotalInterest
    expectedInterestPerInterestCycle
    expectedNumberOfInterestCycles
    interestPayoutOption
    funded
    fundedAt
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    investmentAgreement {
      signed
      signature
      signedAgreementUrl
      signedAt
      __typename
    }
    isBackdated
    nextInterestPerInterestCycle
    isRenewed
    investmentStep
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvestmentMutationVariables,
  APITypes.DeleteInvestmentMutation
>;
export const createPayments = /* GraphQL */ `mutation CreatePayments(
  $input: CreatePaymentsInput!
  $condition: ModelPaymentsConditionInput
) {
  createPayments(input: $input, condition: $condition) {
    id
    paymentCategory
    paymentType
    currency
    amount
    expectedDate
    actualDate
    initiatedBy
    notes
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentsMutationVariables,
  APITypes.CreatePaymentsMutation
>;
export const updatePayments = /* GraphQL */ `mutation UpdatePayments(
  $input: UpdatePaymentsInput!
  $condition: ModelPaymentsConditionInput
) {
  updatePayments(input: $input, condition: $condition) {
    id
    paymentCategory
    paymentType
    currency
    amount
    expectedDate
    actualDate
    initiatedBy
    notes
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentsMutationVariables,
  APITypes.UpdatePaymentsMutation
>;
export const deletePayments = /* GraphQL */ `mutation DeletePayments(
  $input: DeletePaymentsInput!
  $condition: ModelPaymentsConditionInput
) {
  deletePayments(input: $input, condition: $condition) {
    id
    paymentCategory
    paymentType
    currency
    amount
    expectedDate
    actualDate
    initiatedBy
    notes
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentsMutationVariables,
  APITypes.DeletePaymentsMutation
>;
export const createMonthlyInterestBankPayouts = /* GraphQL */ `mutation CreateMonthlyInterestBankPayouts(
  $input: CreateMonthlyInterestBankPayoutsInput!
  $condition: ModelMonthlyInterestBankPayoutsConditionInput
) {
  createMonthlyInterestBankPayouts(input: $input, condition: $condition) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMonthlyInterestBankPayoutsMutationVariables,
  APITypes.CreateMonthlyInterestBankPayoutsMutation
>;
export const updateMonthlyInterestBankPayouts = /* GraphQL */ `mutation UpdateMonthlyInterestBankPayouts(
  $input: UpdateMonthlyInterestBankPayoutsInput!
  $condition: ModelMonthlyInterestBankPayoutsConditionInput
) {
  updateMonthlyInterestBankPayouts(input: $input, condition: $condition) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMonthlyInterestBankPayoutsMutationVariables,
  APITypes.UpdateMonthlyInterestBankPayoutsMutation
>;
export const deleteMonthlyInterestBankPayouts = /* GraphQL */ `mutation DeleteMonthlyInterestBankPayouts(
  $input: DeleteMonthlyInterestBankPayoutsInput!
  $condition: ModelMonthlyInterestBankPayoutsConditionInput
) {
  deleteMonthlyInterestBankPayouts(input: $input, condition: $condition) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMonthlyInterestBankPayoutsMutationVariables,
  APITypes.DeleteMonthlyInterestBankPayoutsMutation
>;
export const createDueInterestBankPayouts = /* GraphQL */ `mutation CreateDueInterestBankPayouts(
  $input: CreateDueInterestBankPayoutsInput!
  $condition: ModelDueInterestBankPayoutsConditionInput
) {
  createDueInterestBankPayouts(input: $input, condition: $condition) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDueInterestBankPayoutsMutationVariables,
  APITypes.CreateDueInterestBankPayoutsMutation
>;
export const updateDueInterestBankPayouts = /* GraphQL */ `mutation UpdateDueInterestBankPayouts(
  $input: UpdateDueInterestBankPayoutsInput!
  $condition: ModelDueInterestBankPayoutsConditionInput
) {
  updateDueInterestBankPayouts(input: $input, condition: $condition) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDueInterestBankPayoutsMutationVariables,
  APITypes.UpdateDueInterestBankPayoutsMutation
>;
export const deleteDueInterestBankPayouts = /* GraphQL */ `mutation DeleteDueInterestBankPayouts(
  $input: DeleteDueInterestBankPayoutsInput!
  $condition: ModelDueInterestBankPayoutsConditionInput
) {
  deleteDueInterestBankPayouts(input: $input, condition: $condition) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDueInterestBankPayoutsMutationVariables,
  APITypes.DeleteDueInterestBankPayoutsMutation
>;
export const createReferences = /* GraphQL */ `mutation CreateReferences(
  $input: CreateReferencesInput!
  $condition: ModelReferencesConditionInput
) {
  createReferences(input: $input, condition: $condition) {
    id
    reference
    amount
    currency
    Status
    ProofOfPaymentUrl
    notes
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    receivedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReferencesMutationVariables,
  APITypes.CreateReferencesMutation
>;
export const updateReferences = /* GraphQL */ `
  mutation UpdateReferences(
    $input: UpdateReferencesInput!
    $condition: ModelReferencesConditionInput
  ) {
    updateReferences(input: $input, condition: $condition) {
      id
      Status
      ProofOfPaymentUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferences = /* GraphQL */ `mutation DeleteReferences(
  $input: DeleteReferencesInput!
  $condition: ModelReferencesConditionInput
) {
  deleteReferences(input: $input, condition: $condition) {
    id
    reference
    amount
    currency
    Status
    ProofOfPaymentUrl
    notes
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    receivedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReferencesMutationVariables,
  APITypes.DeleteReferencesMutation
>;
export const createFxTransactions = /* GraphQL */ `mutation CreateFxTransactions(
  $input: CreateFxTransactionsInput!
  $condition: ModelFxTransactionsConditionInput
) {
  createFxTransactions(input: $input, condition: $condition) {
    id
    fxRate
    fromCurrency
    fromAmount
    toCurrency
    toAmount
    currencyPair
    fee
    buyPrice
    sellPrice
    Status
    ProofOfPaymentUrl
    userReference
    convertFromWallet
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFxTransactionsMutationVariables,
  APITypes.CreateFxTransactionsMutation
>;
export const updateFxTransactions = /* GraphQL */ `mutation UpdateFxTransactions(
  $input: UpdateFxTransactionsInput!
  $condition: ModelFxTransactionsConditionInput
) {
  updateFxTransactions(input: $input, condition: $condition) {
    id
    fxRate
    fromCurrency
    fromAmount
    toCurrency
    toAmount
    currencyPair
    fee
    buyPrice
    sellPrice
    Status
    ProofOfPaymentUrl
    userReference
    convertFromWallet
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFxTransactionsMutationVariables,
  APITypes.UpdateFxTransactionsMutation
>;
export const deleteFxTransactions = /* GraphQL */ `mutation DeleteFxTransactions(
  $input: DeleteFxTransactionsInput!
  $condition: ModelFxTransactionsConditionInput
) {
  deleteFxTransactions(input: $input, condition: $condition) {
    id
    fxRate
    fromCurrency
    fromAmount
    toCurrency
    toAmount
    currencyPair
    fee
    buyPrice
    sellPrice
    Status
    ProofOfPaymentUrl
    userReference
    convertFromWallet
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFxTransactionsMutationVariables,
  APITypes.DeleteFxTransactionsMutation
>;
export const createPlatformMetrics = /* GraphQL */ `mutation CreatePlatformMetrics(
  $input: CreatePlatformMetricsInput!
  $condition: ModelPlatformMetricsConditionInput
) {
  createPlatformMetrics(input: $input, condition: $condition) {
    id
    data {
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlatformMetricsMutationVariables,
  APITypes.CreatePlatformMetricsMutation
>;
export const updatePlatformMetrics = /* GraphQL */ `mutation UpdatePlatformMetrics(
  $input: UpdatePlatformMetricsInput!
  $condition: ModelPlatformMetricsConditionInput
) {
  updatePlatformMetrics(input: $input, condition: $condition) {
    id
    data {
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlatformMetricsMutationVariables,
  APITypes.UpdatePlatformMetricsMutation
>;
export const deletePlatformMetrics = /* GraphQL */ `mutation DeletePlatformMetrics(
  $input: DeletePlatformMetricsInput!
  $condition: ModelPlatformMetricsConditionInput
) {
  deletePlatformMetrics(input: $input, condition: $condition) {
    id
    data {
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlatformMetricsMutationVariables,
  APITypes.DeletePlatformMetricsMutation
>;
